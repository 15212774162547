<template>
  <div class="autocomplete" v-click-outside="outsideClick">
    <input
      type="text"
      v-model="search"
      @input="debounceOnChange"
      :isFromLanding="isFromLanding"
      class="formControl"
      :readonly="isReadonly"
      placeholder=" "
    />
    <label class="fieldFloating__label">Location</label>
    <ul v-show="isOpen" class="autocomplete-results">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
      >
        {{ result }}
      </li>
    </ul>
  </div>
</template>
<script>
import { debounce } from "@/utils/debounce";
import { getLocationData } from "@/api/properties";

export default {
  name: "autocomplete",
  props: ["locationData", "isFromLanding", "isReadonly", "fromFront"],
  watch: {
    locationData(value) {
      this.search = value;
    },
  },
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      items: [],
    };
  },
  created() {
    if (this.locationData) {
      this.search = this.locationData;
    }
  },
  methods: {
    debounceOnChange: debounce(function () {
      this.handleLocationChange();
    }, 800),
    handleLocationChange() {
      this.$emit("getLocation", this.search, true);
      if (!this.isFromLanding) {
        this.$emit("getLocation", this.search);
      }

      if (this.search == "") {
        this.isOpen = false;
      } else {
        getLocationData(
          this.search,
          "&sessiontoken=1234567890&types=(cities)&country=IN",
          this.fromFront
        ).then((response) => {
          setTimeout(() => {}, 10);
          this.results = response.data["predictions"].map(function (item) {
            return item["description"];
          });
        });
        this.isOpen = true;
      }
    },
    setResult(result) {
      this.search = result;
      this.isOpen = false;
      this.$emit("getLocation", this.search, true);
    },
    outsideClick() {
      this.isOpen = false;
    },
  },
};
</script>
